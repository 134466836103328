import crypto from "crypto";
import { User } from "next-auth";

import { useGetServiceQuota } from "@/services/restriction/get-service-quota";

import { initializeIntercom } from "../app/[lang]/initializeIntercom";
import { useLang } from "./use-lang";

export const useIntercom = ({
  appId,
  secret,
  userInfo,
  featureToggle,
}: {
  appId: string;
  secret: string;
  userInfo?: User;
  featureToggle?: boolean;
}) => {
  const { data } = useGetServiceQuota();
  const { lang } = useLang();

  if (typeof window === "undefined") return;
  if (!featureToggle) return;

  const email = userInfo?.email ?? "";
  const userId = (userInfo?.id || userInfo?.name) ?? "";
  const userIdentifier = userId ?? "";

  const hash = userId
    ? crypto.createHmac("sha256", secret).update(userIdentifier).digest("hex")
    : "";

  // Workaround for preventing duplicate checklist in different languages
  // If we don't have this workaround, when user switch from en to zh
  // User will have 2 checklists in the Tasks tab

  initializeIntercom({
    appId,
    email,
    userId,
    hash,
    lang,
    reachFreeUsage: data?.blocked ?? false,
    isEnterprise: userInfo?.authProvider === "Business",
    onload: undefined,
  });
};
