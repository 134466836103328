import { Country } from "../../../data/constants/country";

export type Category =
  | "hot-search"
  | "top-gainers"
  | "top-losers"
  | "pe-ratio"
  | "eps"
  | "net-flow"
  | "yield"
  | "monthly-revenue-yoy";

export const categoryListMap: Record<Country, Category[]> = {
  us: [
    "hot-search",
    "top-gainers",
    "top-losers",
    "pe-ratio",
    "eps",
    "net-flow",
    "yield",
  ],
  tw: [
    "top-gainers",
    "top-losers",
    "yield",
    "pe-ratio",
    "eps",
    "monthly-revenue-yoy",
  ],
};

export const checkParams = ({
  country,
  category,
}: {
  country: Country;
  category: Category;
}) => {
  const validCategories = categoryListMap[country];

  if (!validCategories) {
    throw new Error(`Invalid market: ${country}`);
  }

  if (!validCategories.includes(category)) {
    throw new Error(`Invalid category: ${category}`);
  }
};
