import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";

// rootId is different among frameworks
// such as #root in CRA, Storybook and #__next in NextJS
const StyledProvider = ({
  children,
  rootId,
  fontFamily = ["Noto Sans TC", "sans-serif", "system-ui"].join(","),
}: {
  children: React.ReactNode;
  rootId: string;
  fontFamily?: string;
}) => {
  let rootElement: HTMLElement | null = null;
  if (typeof window !== "undefined") {
    rootElement = document.getElementById(rootId);
  }
  const theme = createTheme({
    typography: { fontFamily },
    components: {
      MuiModal: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiPopper: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiPopover: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiMenu: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiDialog: {
        defaultProps: {
          container: rootElement,
        },
      },
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};

export default StyledProvider;
