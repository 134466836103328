export const diffObj = (
  obj1: Record<string, string | number | boolean>,
  obj2: Record<string, string | number | boolean>
): string[] => {
  const allKeys = Array.from(
    new Set([...Object.keys(obj1), ...Object.keys(obj2)])
  );

  return allKeys.reduce<string[]>((acc, key) => {
    if (obj1[key] !== obj2[key]) {
      acc.push(key);
    }
    return acc;
  }, []);
};
