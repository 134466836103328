import { z } from "zod";

export const technicalInputSchema = z.object({
  sma20Day: z.number().nullish(),
  sma50Day: z.number().nullish(),
  sma100Day: z.number().nullish(),
  sma150Day: z.number().nullish(),
  sma200Day: z.number().nullish(),
  high20Day: z.number().nullish(),
  low20Day: z.number().nullish(),
  high50Day: z.number().nullish(),
  low50Day: z.number().nullish(),
  high52Week: z.number().nullish(),
  low52Week: z.number().nullish(),
  atr: z.number().nullish(),
  dailyMaAlignment: z.number().nullish(),
  weeklyMaAlignment: z.number().nullish(),
  smaSlope: z.number().nullish(),
});

export type TechnicalInput = z.infer<typeof technicalInputSchema>;

export const technicalInputInitialState: TechnicalInput = {
  sma20Day: undefined,
  sma50Day: undefined,
  sma100Day: undefined,
  sma150Day: undefined,
  sma200Day: undefined,
  high20Day: undefined,
  low20Day: undefined,
  high50Day: undefined,
  low50Day: undefined,
  high52Week: undefined,
  low52Week: undefined,
  atr: undefined,
  dailyMaAlignment: undefined,
  weeklyMaAlignment: undefined,
  smaSlope: undefined,
};

export type TechnicalInputKey = keyof TechnicalInput;
