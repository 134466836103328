import isEqual from "lodash/isEqual";
import { Session } from "next-auth";
import { z } from "zod";

import { userDataSchema } from "../../login/services/login";

export const matchUserFromApiAndSession = ({
  session,
  user,
}: {
  session: Session;
  user: z.infer<typeof userDataSchema>;
}): boolean => isEqual(parseSession({ session }), parseUser({ user }));

type ComparableUser = Pick<
  Session["user"],
  | "plan"
  | "subscribed"
  | "subscribedAt"
  | "expireAt"
  | "discount"
  | "signUpAt"
  | "subscribing"
>;

/**
 * This is a helper function to parse the user session from the API response.
 */
const parseUser = ({
  user,
}: {
  user: z.infer<typeof userDataSchema>;
}): ComparableUser => ({
  plan: user.profile.plan,
  subscribed: user.profile.subscribed,
  subscribedAt: user.profile.subscribedAt,
  expireAt: user.profile.expireAt,
  discount: user.profile.discount,
  signUpAt: user.profile.signUpAt,
  subscribing: user.profile.subscribing,
});

const parseSession = ({ session }: { session: Session }): ComparableUser => ({
  plan: session.user.plan,
  subscribed: session.user.subscribed,
  subscribedAt: session.user.subscribedAt,
  expireAt: session.user.expireAt,
  discount: session.user.discount,
  signUpAt: session.user.signUpAt,
  subscribing: session.user.subscribing,
});
