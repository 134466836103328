import { useMutation } from "@tanstack/react-query";
import { usePathname } from "next/navigation";
import * as z from "zod";

import { BASE_URL, NIKE_URL } from "@/data/constants/url";
import { useAuth } from "@/features/auth/shared/hooks/use-auth";
import { useLang } from "@/hooks/use-lang";
import { Lang } from "@/i18n-config";

const responseSchema = z.object({
  message: z.string(),
});

export type ResponseData = z.infer<typeof responseSchema>;

type Params = {
  token: string;
  lang: Lang;
  website: string;
};

export const updateUserBrowsingHistory = async ({
  token,
  lang,
  website,
}: Params): Promise<ResponseData> => {
  const endpoint = "/user/behavior";

  const res = await fetch(`${NIKE_URL}${endpoint}`, {
    method: "POST",
    headers: {
      "X-User-Profile": token,
      "Accept-Language": lang,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ website }),
  });

  if (!res.ok) {
    console.error(`API failed (${endpoint})`);
  }

  return responseSchema.parse(await res.json());
};

export const useUpdateUserBrowsingHistory = () => {
  const { lang } = useLang();
  const { backendToken } = useAuth();
  const pathname = usePathname();

  return useMutation(() =>
    updateUserBrowsingHistory({
      token: backendToken ?? "",
      lang,
      website: `${BASE_URL}${pathname}`,
    })
  );
};
