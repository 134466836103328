export const NAVBAR_HEIGHT = 70;
export const MY_NAVBAR_HEIGHT = 64;
export const MY_NAVBAR_MASK_HEIGHT = 28;

export const SIDEBAR_WIDTH = 200;
export const SIDEBAR_COLLAPSED_WIDTH = 70;
export const WATCHLIST_WIDTH = 235;

export const HOME_PAGE_WIDTH = 1280;

export const MY_WIDTH_SMALL = 1024;
export const MY_WIDTH_MEDIUM = 1280;
export const MY_WIDTH_LARGE = 1440;

export const EVENT_BANNER_HEIGHT = 40;

export const LAYOUT_VARIABLES = {
  "--home-page-width": `${HOME_PAGE_WIDTH}px`,
  "--navbar-height": `${NAVBAR_HEIGHT}px`,
  "--sidebar-width": `${SIDEBAR_WIDTH}px`,
  "--sidebar-collapsed-width": `${SIDEBAR_COLLAPSED_WIDTH}px`,
  "--watchlist-width": `${WATCHLIST_WIDTH}px`,
  "--my-navbar-height": `${MY_NAVBAR_HEIGHT}px`,
  "--my-navbar-mask-height": `${MY_NAVBAR_MASK_HEIGHT}px`,
  "--my-navbar-width": `${MY_WIDTH_LARGE}px`,
  "--my-analysis-width": `${MY_WIDTH_LARGE}px`,
  "--my-ranking-width": `${MY_WIDTH_LARGE}px`,
  "--my-screener-width": `${MY_WIDTH_LARGE}px`,
  "--my-notification-width": `${MY_WIDTH_SMALL}px`,
  "--my-13f-width": `${MY_WIDTH_SMALL}px`,
  "--my-market-width": `${MY_WIDTH_SMALL}px`,
  "--my-media-width": `${MY_WIDTH_LARGE}px`,
  "--my-account-width": `${MY_WIDTH_MEDIUM}px`,
  "--my-winwingpt-width": `${MY_WIDTH_LARGE}px`,
  "--my-blog-width": `${MY_WIDTH_MEDIUM}px`,
  "--my-event-banner-height": `${EVENT_BANNER_HEIGHT}px`,
} as React.CSSProperties;
