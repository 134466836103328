import { useMutation, useQueryClient } from "@tanstack/react-query";
import { usePathname } from "next/navigation";
import * as z from "zod";

import { BASE_URL, NIKE_URL } from "@/data/constants/url";
import { useAuth } from "@/features/auth/shared/hooks/use-auth";
import { useLang } from "@/hooks/use-lang";
import { Lang } from "@/i18n-config";

const responseSchema = z.object({
  message: z.string(),
});

export type ResponseData = z.infer<typeof responseSchema>["message"];

type Params = {
  token: string;
  lang: Lang;
  website: string;
};

export const addServiceQuota = async ({
  token,
  lang,
  website,
}: Params): Promise<ResponseData> => {
  const endpoint = "/service-quota";

  const res = await fetch(`${NIKE_URL}${endpoint}`, {
    method: "POST",
    body: JSON.stringify({ website }),
    headers: {
      "X-User-Profile": token,
      "Accept-Language": lang,
      "Content-Type": "application/json",
    },
  });

  if (!res.ok) {
    throw new Error(`API failed (${endpoint})`);
  }

  return responseSchema.parse(await res.json()).message;
};

export const useAddServiceQuota = () => {
  const queryClient = useQueryClient();
  const { lang } = useLang();
  const { backendToken } = useAuth();
  const pathname = usePathname();

  return useMutation(
    () =>
      addServiceQuota({
        token: backendToken ?? "",
        lang,
        website: `${BASE_URL}${pathname}`,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["service-quota", lang, backendToken]);
      },
    }
  );
};
