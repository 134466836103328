/* eslint-disable prefer-rest-params */
import { isMobile } from "react-device-detect";

import { Lang } from "@/i18n-config";

/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    intercomSettings: {
      api_base: string;
      app_id: string;
      language_webapp: Lang;
      is_mobile: boolean;
      domain_name: string;
      is_enterprise: boolean;
      language_override: string;
      reach_free_usage: boolean;
      hide_default_launcher: boolean;
      email?: string;
      user_id?: string;
      user_hash?: string;
    };

    Intercom: {
      (...args: any[]): void;
      q: any[];
      c: (...args: any[]) => void;
    };

    attachEvent: (event: string, listener: EventListener) => void;
  }
}

// `language_override` field requires `zh-TW` lang code to display Chinese interface
const LangMap = {
  zh: "zh-TW",
  en: "en",
};

type Props = {
  appId: string;
  userId: string;
  email: string;
  hash: string;
  lang: Lang;
  reachFreeUsage: boolean;
  isEnterprise: boolean;
  onload?: () => void;
};

export function initializeIntercom({
  appId,
  userId,
  email,
  hash,
  lang,
  reachFreeUsage,
  isEnterprise,
  onload = () => {},
}: Props) {
  if (typeof window === "undefined") return;
  const appDomain = window.location.hostname.split(".")[0];

  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: appId,
    language_override: LangMap[lang],
    reach_free_usage: reachFreeUsage,
    language_webapp: lang,
    is_mobile: isMobile,
    domain_name: appDomain || "www",
    hide_default_launcher: isMobile,
    is_enterprise: isEnterprise,
  };

  if (userId && hash) {
    window.intercomSettings.user_id = userId;
    window.intercomSettings.email = email;
    window.intercomSettings.user_hash = hash;
  }

  (function () {
    const w = window;
    const ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      const d = document;
      const i = function () {
        if (i.c) i.c(arguments);
      };
      i.q = [] as any[];
      w.Intercom = i;
      i.c = function (args: any) {
        i.q.push(args);
      };
      const l = function () {
        const s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${appId}`;
        const x = d.getElementsByTagName("script")[0];
        x.parentNode?.insertBefore(s, x);
        if (onload) s.onload = onload;
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
}
