import { z } from "zod";

export const macroInputSharedSchema = z.object({
  sector: z.array(z.string()).optional(),
  industry: z.array(z.string()).optional(),
  marketCap: z.number().optional(),
  volume20MA: z.number().optional(),
  rsRating: z.number().optional(),
});

export const macroInputTWSchema = macroInputSharedSchema.extend({
  retailLongMarginUsage: z.number().optional(),
  retailMarginRatio: z.number().optional(),
});

export const macroInputUSSchema = macroInputSharedSchema.extend({
  shortInterest: z.number().optional(),
  analystRating: z.number().optional(),
});

export type MacroInputSharedInput = z.infer<typeof macroInputSharedSchema>;
export type MacroInputTWInput = z.infer<typeof macroInputTWSchema>;
export type MacroInputUSInput = z.infer<typeof macroInputUSSchema>;

export const macroInputInitialState: MacroInputSharedInput = {
  sector: [],
  industry: [],
  marketCap: undefined,
  volume20MA: undefined,
  rsRating: undefined,
};

export const macroInputTWInitialState: MacroInputTWInput = {
  ...macroInputInitialState,
  retailLongMarginUsage: undefined,
  retailMarginRatio: undefined,
};

export const macroInputUSInitialState: MacroInputUSInput = {
  ...macroInputInitialState,
  shortInterest: undefined,
  analystRating: undefined,
};
