/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
  FundamentalUSInput,
  GrowinInput,
  GrowinInputInitialState,
  MacroInputUSInput,
  PatternsInput,
  TechnicalInput,
  fundamentalUSInitialState,
  macroInputUSInitialState,
  patternsInputInitialState,
  technicalInputInitialState,
} from "./types";

export type ScreenerStateUS = {
  macro: MacroInputUSInput;
  fundamental: FundamentalUSInput;
  technical: TechnicalInput;
  patterns: PatternsInput;
  growin: GrowinInput;
};

export const initialState: ScreenerStateUS & { activeTab: string } = {
  activeTab: "macro",
  macro: macroInputUSInitialState,
  fundamental: fundamentalUSInitialState,
  technical: technicalInputInitialState,
  patterns: patternsInputInitialState,
  growin: GrowinInputInitialState,
};

export type MacroInputKeyUS = keyof ScreenerStateUS["macro"];
export type FundamentalInputKeyUS = keyof ScreenerStateUS["fundamental"];

export const USscreenerSlice = createSlice({
  name: "screenerUS",
  initialState,
  reducers: {
    updateMacroInputsUS: (
      state,
      action: PayloadAction<{
        key: keyof ScreenerStateUS["macro"];
        value: any;
      }>
    ) => {
      state.macro[action.payload.key] = action.payload.value;
    },
    updateFundamentalInputsUS: (
      state,
      action: PayloadAction<{
        key: keyof ScreenerStateUS["fundamental"];
        value: any;
      }>
    ) => {
      state.fundamental[action.payload.key] = action.payload.value;
    },
    updateTechnicalInputsUS: (
      state,
      action: PayloadAction<{
        key: keyof ScreenerStateUS["technical"];
        value: any;
      }>
    ) => {
      state.technical[action.payload.key] = action.payload.value;
    },
    updatePatternsInputsUS: (
      state,
      action: PayloadAction<{
        key: keyof ScreenerStateUS["patterns"];
        value: any;
      }>
    ) => {
      if (state.patterns) {
        state.patterns[action.payload.key] = action.payload.value;
      }
    },
    updateGrowinFiveScoreInputsUS: (
      state,
      action: PayloadAction<{
        key: keyof ScreenerStateUS["growin"]["fiveScore"];
        value: any;
      }>
    ) => {
      state.growin.fiveScore[action.payload.key] = action.payload.value;
    },
    updateGrowinPowerSqueezeDayInputsUS: (
      state,
      action: PayloadAction<{
        key: keyof ScreenerStateUS["growin"]["powerSqueezeDay"];
        value: any;
      }>
    ) => {
      state.growin.powerSqueezeDay[action.payload.key] = action.payload.value;
    },
    updateGrowinPowerSqueezeWeekInputsUS: (
      state,
      action: PayloadAction<{
        key: keyof ScreenerStateUS["growin"]["powerSqueezeWeek"];
        value: any;
      }>
    ) => {
      state.growin.powerSqueezeWeek[action.payload.key] = action.payload.value;
    },
    updateGrowinPowerSqueezeMonthInputsUS: (
      state,
      action: PayloadAction<{
        key: keyof ScreenerStateUS["growin"]["powerSqueezeMonth"];
        value: any;
      }>
    ) => {
      state.growin.powerSqueezeMonth[action.payload.key] = action.payload.value;
    },
    updateGrowinSurfingTrendInputsUS: (
      state,
      action: PayloadAction<{
        key: keyof ScreenerStateUS["growin"]["surfingTrend"];
        value: any;
      }>
    ) => {
      state.growin.surfingTrend[action.payload.key] = action.payload.value;
    },
    updateGrowinMeanRevertingRadarInputsUS: (
      state,
      action: PayloadAction<{
        key: keyof ScreenerStateUS["growin"]["meanRevertingRadar"];
        value: any;
      }>
    ) => {
      state.growin.meanRevertingRadar[action.payload.key] =
        action.payload.value;
    },
    resetScreenerUS: (state) => ({
      ...initialState,
      activeTab: state.activeTab,
    }),
    updateActiveTabUS: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload;
    },
  },
});

export const {
  resetScreenerUS,
  updateMacroInputsUS,
  updateFundamentalInputsUS,
  updateTechnicalInputsUS,
  updatePatternsInputsUS,
  updateGrowinFiveScoreInputsUS,
  updateGrowinPowerSqueezeDayInputsUS,
  updateGrowinPowerSqueezeWeekInputsUS,
  updateGrowinPowerSqueezeMonthInputsUS,
  updateGrowinSurfingTrendInputsUS,
  updateGrowinMeanRevertingRadarInputsUS,
  updateActiveTabUS,
} = USscreenerSlice.actions;

export default USscreenerSlice.reducer;
