import { usePathname, useSearchParams } from "next/navigation";
import { useEffect, useMemo, useRef } from "react";

import { diffObj } from "@/utils/diff-obj";

import { usePrevious } from "./use-previous";

const fromSearchParams = (searchParams: URLSearchParams) => {
  return Object.fromEntries(searchParams.entries());
};

/**
 * @param pathnames
 * @param escapeQueries
 * @param callback
 * @returns void
 * @description
 * useSearchParamsChange is a hook that listens to pathname change and search params change.
 * It will call the callback function when the pathname matches the pathnames and the search params are not empty.
 * It will also escape the search params that are in the escapeQueries array.
 * It will also call the callback function when the search params are empty.
 */
export const useSearchParamsChange = ({
  pathnames,
  escapeQueries,
  callback,
}: {
  pathnames: (string | RegExp)[];
  escapeQueries?: string[];
  callback?: (searchParams?: URLSearchParams) => void;
}) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const prevSearchParams = usePrevious<typeof searchParams>(searchParams);

  // TMP: hotfix for now, should be a more generic solution
  // For screener page, if we have search params, we should trigger the callback for the first render
  const isFirstRender = useRef(true);
  const isScreenerPage = pathname.includes("/screener");

  const isEscape = useMemo(() => {
    if (isScreenerPage && isFirstRender.current) {
      isFirstRender.current = false;
      return false;
    }

    const diffKeys = diffObj(
      fromSearchParams(prevSearchParams),
      fromSearchParams(searchParams)
    );
    return diffKeys.every((key) => escapeQueries?.includes(key));
  }, [escapeQueries, searchParams, prevSearchParams, isScreenerPage]);
  const matchPathname = useMemo(
    () =>
      pathnames.some((p) =>
        p instanceof RegExp ? p.test(pathname) : pathname.includes(p)
      ),
    [pathnames, pathname]
  );

  useEffect(() => {
    if (pathnames.length === 0 || isEscape) return;
    if (matchPathname && callback && searchParams.size > 0) {
      callback?.(searchParams);
    }
  }, [pathnames, pathname, searchParams, isEscape, callback, matchPathname]);
};
