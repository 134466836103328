import {
  BEGINNING_ACCESS_COUNT,
  FREE_ACCESS_COUNT,
} from "@/data/constants/service-limitation";
import { Translation } from "@/data/lang";
import { Lang } from "@/i18n-config";

import { usageRestrictedPlans } from "../data/stripe-plan";
import { PlanType, UserIdentityType } from "../types/stripe-plan";

export const getIdentityNameMap = (
  t: Translation
): Record<UserIdentityType, string> =>
  ({
    Guest: t.stripe.plan.guest,
    Free: t.stripe.plan.free,
    "Beginning Monthly": t.stripe.plan.beginningMonthly,
    "Beginning Yearly": t.stripe.plan.beginningYearly,
    "Basic Monthly": t.stripe.plan.basicMonthly,
    "Basic Yearly": t.stripe.plan.basicYearly,
    "Premium Monthly": t.stripe.plan.premiumMonthly,
    "Premium Yearly": t.stripe.plan.premiumYearly,
  } as const);

export const getPlanPeriod = (plan: PlanType) =>
  plan.split(" ")[1]?.toLowerCase() as "monthly" | "yearly";

/**
 * Splits a plan name into its name and period string.
 * @param plan The plan name to split.
 * @returns A tuple containing the plan name and its period (if any).
 * @example
 * const [name, period] = parsePlanName({ plan: "進階方案（年繳）" });
 * name: "進階方案", period: "年繳"
 *
 * const [name, period] = parsePlanName({ plan: "免費方案" });
 * name: "免費方案", period: undefined
 */
export const parsePlanName = ({
  lang,
  planName,
}: {
  lang: Lang;
  planName: string;
}) => {
  const [name, period] = planName.split(lang === "zh" ? "（" : " (");
  return [name, period?.replace(lang === "zh" ? "）" : ")", "")];
};

const usageMap: Partial<Record<PlanType, number>> = {
  Free: FREE_ACCESS_COUNT,
  "Beginning Monthly": BEGINNING_ACCESS_COUNT,
  "Beginning Yearly": BEGINNING_ACCESS_COUNT,
};

export const getMaxBrowsingUsage = ({ plan }: { plan: PlanType }): number => {
  return usageMap[plan] ?? 0;
};

export const checkPlanIsUsageRestricted = ({ plan }: { plan: PlanType }) =>
  usageRestrictedPlans.includes(plan.split(" ")[0]);
