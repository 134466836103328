import { SignInOptions, signIn, useSession } from "next-auth/react";
import { useState } from "react";

interface OneTapSignInOptions {
  parentContainerId?: string;
}

export const useOneTapSignIn = (
  options?: OneTapSignInOptions &
    Pick<SignInOptions, "redirect" | "callbackUrl">
) => {
  const { parentContainerId } = options || {};
  const [isLoading, setIsLoading] = useState(false);

  // If user is unauthenticated, google one tap ui is initialized and rendered
  const { status } = useSession({
    required: true,
    onUnauthenticated() {
      if (isLoading) return;

      const { google } = window;
      if (!google) return;

      google.accounts.id.initialize({
        client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ?? "",
        callback: async (response) => {
          setIsLoading(true);

          await signIn("google-one-tap", {
            credential: response.credential,
            redirect: true,
            ...options,
          });
          setIsLoading(false);
        },
        prompt_parent_id: parentContainerId,
      });

      // log out some error situations and reason why the google one tap is not displayed.
      google.accounts.id.prompt((notification) => {
        if (notification.isNotDisplayed())
          console.log(
            "getNotDisplayedReason :",
            notification.getNotDisplayedReason()
          );
        else if (notification.isSkippedMoment())
          console.log("getSkippedReason :", notification.getSkippedReason());
        else if (notification.isDismissedMoment())
          console.log(
            "getDismissedReason :",
            notification.getDismissedReason()
          );
      });
    },
  });
  return { isLoading, status };
};
