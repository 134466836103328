import { z } from "zod";

// Define Zod schemas first
export const bullishPatternSchema = z.enum([
  "momentumSqueezePattern",
  "cupAndHandle",
  "WBottom",
  "ascendingTriangle",
  "headAndShouldersBottom",
  "volatilityContraction",
]);

export const bearishPatternSchema = z.enum([
  "invertedCupAndHandle",
  "MTop",
  "descendingTriangle",
  "headAndShouldersTop",
]);

export const patternSchema = z.union([
  bullishPatternSchema,
  bearishPatternSchema,
]);
export const patternWithNoneSchema = z.union([
  patternSchema,
  z.literal("None"),
]);

export const patternsInputSchema = z.object({
  dayPattern: patternWithNoneSchema,
  weekPattern: patternWithNoneSchema,
  monthPattern: patternWithNoneSchema,
});

export type BullishPatternType = z.infer<typeof bullishPatternSchema>;
export type BearishPatternType = z.infer<typeof bearishPatternSchema>;
export type PatternType = z.infer<typeof patternSchema>;
export type PatternTypeWithNone = z.infer<typeof patternWithNoneSchema>;
export type PatternsInput = z.infer<typeof patternsInputSchema>;
export type PatternsInputKey = keyof PatternsInput;

export const patternsInputInitialState: PatternsInput = {
  dayPattern: "None",
  weekPattern: "None",
  monthPattern: "None",
};
