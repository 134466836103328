/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
  FundamentalTWInput,
  GrowinInput,
  GrowinInputInitialState,
  MacroInputTWInput,
  PatternsInput,
  TechnicalInput,
  fundamentalTWInitialState,
  macroInputTWInitialState,
  patternsInputInitialState,
  technicalInputInitialState,
} from "./types";

export interface ScreenerStateTW {
  macro: MacroInputTWInput;
  fundamental: FundamentalTWInput;
  technical: TechnicalInput;
  patterns: PatternsInput;
  growin: GrowinInput;
}

export const initialState: ScreenerStateTW & { activeTab: string } = {
  activeTab: "macro",
  macro: macroInputTWInitialState,
  fundamental: fundamentalTWInitialState,
  technical: technicalInputInitialState,
  patterns: patternsInputInitialState,
  growin: GrowinInputInitialState,
};

export type MacroInputKeyTW = keyof ScreenerStateTW["macro"];
export type FundamentalInputKeyTW = keyof ScreenerStateTW["fundamental"];

export const TWscreenerSlice = createSlice({
  name: "TWscreener",
  initialState,
  reducers: {
    updateMacroInputsTW: (
      state,
      action: PayloadAction<{
        key: keyof ScreenerStateTW["macro"];
        value: any;
      }>
    ) => {
      state.macro[action.payload.key] = action.payload.value;
    },
    updateFundamentalInputsTW: (
      state,
      action: PayloadAction<{
        key: keyof ScreenerStateTW["fundamental"];
        value: any;
      }>
    ) => {
      state.fundamental[action.payload.key] = action.payload.value;
    },
    updateTechnicalInputsTW: (
      state,
      action: PayloadAction<{
        key: keyof ScreenerStateTW["technical"];
        value: any;
      }>
    ) => {
      state.technical[action.payload.key] = action.payload.value;
    },
    updatePatternsInputsTW: (
      state,
      action: PayloadAction<{
        key: keyof ScreenerStateTW["patterns"];
        value: any;
      }>
    ) => {
      if (state.patterns) {
        state.patterns[action.payload.key] = action.payload.value;
      }
    },
    updateGrowinFiveScoreInputsTW: (
      state,
      action: PayloadAction<{
        key: keyof ScreenerStateTW["growin"]["fiveScore"];
        value: any;
      }>
    ) => {
      state.growin.fiveScore[action.payload.key] = action.payload.value;
    },
    updateGrowinPowerSqueezeDayInputsTW: (
      state,
      action: PayloadAction<{
        key: keyof ScreenerStateTW["growin"]["powerSqueezeDay"];
        value: any;
      }>
    ) => {
      state.growin.powerSqueezeDay[action.payload.key] = action.payload.value;
    },
    updateGrowinPowerSqueezeWeekInputsTW: (
      state,
      action: PayloadAction<{
        key: keyof ScreenerStateTW["growin"]["powerSqueezeWeek"];
        value: any;
      }>
    ) => {
      state.growin.powerSqueezeWeek[action.payload.key] = action.payload.value;
    },
    updateGrowinPowerSqueezeMonthInputsTW: (
      state,
      action: PayloadAction<{
        key: keyof ScreenerStateTW["growin"]["powerSqueezeMonth"];
        value: any;
      }>
    ) => {
      state.growin.powerSqueezeMonth[action.payload.key] = action.payload.value;
    },
    updateGrowinSurfingTrendInputsTW: (
      state,
      action: PayloadAction<{
        key: keyof ScreenerStateTW["growin"]["surfingTrend"];
        value: any;
      }>
    ) => {
      state.growin.surfingTrend[action.payload.key] = action.payload.value;
    },
    updateGrowinMeanRevertingRadarInputsTW: (
      state,
      action: PayloadAction<{
        key: keyof ScreenerStateTW["growin"]["meanRevertingRadar"];
        value: any;
      }>
    ) => {
      state.growin.meanRevertingRadar[action.payload.key] =
        action.payload.value;
    },
    resetScreenerTW: (state) => ({
      ...initialState,
      activeTab: state.activeTab,
    }),
    updateActiveTabTW: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload;
    },
  },
});

export const {
  resetScreenerTW,
  updateMacroInputsTW,
  updateFundamentalInputsTW,
  updateTechnicalInputsTW,
  updatePatternsInputsTW,
  updateGrowinFiveScoreInputsTW,
  updateGrowinPowerSqueezeDayInputsTW,
  updateGrowinPowerSqueezeWeekInputsTW,
  updateGrowinPowerSqueezeMonthInputsTW,
  updateGrowinSurfingTrendInputsTW,
  updateGrowinMeanRevertingRadarInputsTW,
  updateActiveTabTW,
} = TWscreenerSlice.actions;

export default TWscreenerSlice.reducer;
