export const stripePlans = [
  "Free",
  "Beginning Monthly",
  "Beginning Yearly",
  "Basic Monthly",
  "Basic Yearly",
  "Premium Monthly",
  "Premium Yearly",
] as const;

export const usageRestrictedPlans = ["Free", "Beginning"];
