"use client";

import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import { useAuth } from "@/features/auth/shared/hooks/use-auth";
import { categoryListMap } from "@/features/ranking/data/category-data";
import { useLang } from "@/hooks/use-lang";
import { useUpdateUserBrowsingHistory } from "@/services/behavior/update-user-browsing-history";
import { useAddServiceQuota } from "@/services/restriction/add-service-quota";
import { ServiceQuotaData } from "@/services/restriction/get-service-quota";

import { usePathnameChange } from "../../hooks/use-pathname-change";
import { useSearchParamsChange } from "../../hooks/use-search-params-change";

// List of pathnames that will trigger pathname change event
const pathnameChangePathList = [
  /\/analysis\/[a-zA-Z0-9]+/,
  /\/market-analysis(?!\/market-early-warning-indicator\/funding-level-backtest)/,
  "/13f",
  ...categoryListMap.us
    .filter((_, index) => index < 3)
    .map((category) => `/my/ranking/us/${category}`),
  ...categoryListMap.tw
    .filter((_, index) => index < 2)
    .map((category) => `/my/ranking/tw/${category}`),
];

// List of pathnames that will trigger query change event
const queryChangePathList = ["/analysis", "/13f", "/market-analysis"];

// List of queries that will not trigger query change event
const escapeQueryChangeQueryList = ["sort", "sortBy", "page", "pageSize"];

export const NavigationEvents = () => {
  const queryClient = useQueryClient();
  const { lang } = useLang();
  const { backendToken, isAuthenticated } = useAuth();
  const quotaData = queryClient.getQueryData<ServiceQuotaData>([
    "service-quota",
    lang,
    backendToken,
  ]);
  const isReachQuota = quotaData?.blocked || (quotaData?.remains ?? 0) <= 0;
  const { mutate: addServiceQuota } = useAddServiceQuota();
  const { mutate: updateBrowsingHistory } = useUpdateUserBrowsingHistory();

  const handleUpdateBrowsingHistory = useCallback(() => {
    if (!isAuthenticated) return;
    updateBrowsingHistory();
  }, [isAuthenticated, updateBrowsingHistory]);

  const handleAddServiceQuota = useCallback(() => {
    if (isReachQuota) return;
    if (!isAuthenticated) return;
    addServiceQuota();
  }, [isAuthenticated, addServiceQuota, isReachQuota]);

  // track user access counts
  usePathnameChange({
    pathnames: pathnameChangePathList,
    callback: handleAddServiceQuota,
  });

  // track user browsing history
  usePathnameChange({
    pathnames: ["/"],
    callback: handleUpdateBrowsingHistory,
  });

  useSearchParamsChange({
    pathnames: queryChangePathList,
    escapeQueries: escapeQueryChangeQueryList,
    callback: handleAddServiceQuota,
  });

  return null;
};
