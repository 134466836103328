import { combineReducers } from "@reduxjs/toolkit";

import chatReducer from "./chat-slice";
import loginDialogReducer from "./dialog/login-dialog-slice";
import searchDialogReducer from "./dialog/search-dialog-slice";
import subscribeDialogReducer from "./dialog/subscribe-dialog-slice";
import vipDialogReducer from "./dialog/vip-dialog-slice";
import screenerStrategyReducer from "./screener/strategy-slice";
import twScreenerReducer from "./screener/tw-screener-slice";
import usScreenerReducer from "./screener/us-screener-slice";

const reducer = combineReducers({
  searchDialog: searchDialogReducer,
  loginDialog: loginDialogReducer,
  subscribeDialog: subscribeDialogReducer,
  vipDialog: vipDialogReducer,
  chat: chatReducer,
  usScreener: usScreenerReducer,
  twScreener: twScreenerReducer,
  screenerStrategy: screenerStrategyReducer,
});

const rootReducer: typeof reducer = (state, action) => {
  return reducer(state, action);
};

export default rootReducer;
