import { createSlice } from "@reduxjs/toolkit";

export interface loginDialogState {
  isOpen: boolean;
}

const initialState: loginDialogState = {
  isOpen: false,
};

export const loginDialogSlice = createSlice({
  name: "loginDialog",
  initialState,
  reducers: {
    openLoginDialog(state) {
      state.isOpen = true;
    },
    closeLoginDialog(state) {
      state.isOpen = false;
    },
  },
});

export const { openLoginDialog, closeLoginDialog } = loginDialogSlice.actions;

export default loginDialogSlice.reducer;
