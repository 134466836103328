import { useQuery } from "@tanstack/react-query";
import { useSession } from "next-auth/react";
import { useEffect } from "react";

import { TV_URL } from "@/data/constants/url";
import {
  AuthProvider,
  userDataSchema,
} from "@/features/auth/login/services/login";
import { useAuth } from "@/features/auth/shared/hooks/use-auth";
import { matchUserFromApiAndSession } from "@/features/auth/shared/utils/match-user-session";
import { useLang } from "@/hooks/use-lang";
import { Lang } from "@/i18n-config";

interface SyncUserParams {
  token: string;
  authProvider: AuthProvider;
  lang: Lang;
}

const syncUser = async ({ token, authProvider, lang }: SyncUserParams) => {
  const endpoint = "/user/sign-in";
  const res = await fetch(`${TV_URL}/user/sign-in`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-User-Profile": token,
      "X-User-Auth": authProvider,
      "Accept-Language": lang,
      platform: "stock_mining",
    },
  });

  if (!res.ok) throw new Error(`API failed (${endpoint})`);
  return userDataSchema.parse(await res.json());
};

export const useSyncSubscription = () => {
  const { backendToken: token, profile } = useAuth();
  const { lang } = useLang();

  return useQuery(
    ["sync-subscription", token, lang],
    () =>
      syncUser({
        token: token ?? "",
        authProvider: profile?.authProvider ?? "Original",
        lang,
      }),
    {
      enabled: !!token,
      refetchInterval: (data) =>
        data?.profile.plan === "Free" || data?.profile.subscribing === false
          ? 2000
          : false,
    }
  );
};

export const useSyncSession = () => {
  const { update, data: session } = useSession();
  const token = session?.user.backendToken;
  const profile = session?.user;
  const { lang } = useLang();

  const { data, isLoading, isError } = useQuery(
    ["sync-session", token, lang],
    () =>
      syncUser({
        token: token ?? "",
        authProvider: profile?.authProvider ?? "Original",
        lang,
      }),
    { enabled: !!token }
  );

  useEffect(() => {
    // if (isError) {
    //   logout();
    //   router.push(`/${lang}/${links.authLogin}`);
    //   return;
    // }
    if (!data || !session || isLoading) return;
    if (matchUserFromApiAndSession({ user: data, session })) return;

    update({
      ...session,
      user: {
        ...session?.user,
        ...data.profile,
        backendToken: data.token,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isError]);
};
