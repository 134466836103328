import { createSlice } from "@reduxjs/toolkit";

export type SubscribeDialogState = {
  isOpen: boolean;
};

const initialState: SubscribeDialogState = {
  isOpen: false,
};

export const subscribeDialogSlice = createSlice({
  name: "subscribeDialog",
  initialState,
  reducers: {
    openSubscribeDialog(state) {
      state.isOpen = true;
    },
    closeSubscribeDialog(state) {
      state.isOpen = false;
    },
  },
});

export const { openSubscribeDialog, closeSubscribeDialog } =
  subscribeDialogSlice.actions;

export default subscribeDialogSlice.reducer;
