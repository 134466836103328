import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type MessageProp = {
  messageId?: string;
  type: "client" | "gpt" | "element" | "chart";
  content: string | JSX.Element;
};

export type ChatState = {
  messages: MessageProp[];
  isStreaming: boolean;
};

export const initialState: ChatState = {
  messages: [],
  isStreaming: false,
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<MessageProp>) => {
      state.messages.push(action.payload);
    },
    addMessages: (state, action: PayloadAction<MessageProp[]>) => {
      action.payload.forEach((message) => {
        state.messages.push(message);
      });
    },
    updateMessage: (
      state,
      action: PayloadAction<Omit<MessageProp, "type">>
    ) => {
      const messageId = state.messages.findIndex(
        (message) => message.messageId === action.payload.messageId
      );
      if (messageId !== -1) {
        state.messages[messageId] = {
          ...state.messages[messageId],
          ...action.payload,
        };
      }
    },
    resetChat: (state) => {
      state.messages = [];
    },
    setIsStreaming: (state, action: PayloadAction<boolean>) => {
      state.isStreaming = action.payload;
    },
    resetIsStreaming: (state) => {
      state.isStreaming = false;
    },
  },
});

export const {
  addMessage,
  addMessages,
  updateMessage,
  resetChat,
  resetIsStreaming,
  setIsStreaming,
} = chatSlice.actions;

export default chatSlice.reducer;
