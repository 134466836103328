import { z } from "zod";

export const fundamentalSharedSchema = z.object({
  pe: z.number().nullish(),
  ps: z.number().nullish(),
  pb: z.number().nullish(),
  epsGrowthQoq: z.number().nullish(),
  epsGrowthYoy: z.number().nullish(),
  epsGrowthPastFiveYears: z.number().nullish(),
  revenueGrowthQoq: z.number().nullish(),
  revenueGrowthYoy: z.number().nullish(),
  revenueGrowthPastFiveYears: z.number().nullish(),
  evOverEbitda: z.number().nullish(),
  roe: z.number().nullish(),
  roa: z.number().nullish(),
  roic: z.number().nullish(),
  currentRatio: z.number().nullish(),
  quickRatio: z.number().nullish(),
  debtEquityRatio: z.number().nullish(),
  grossMargin: z.number().nullish(),
  operatingMargin: z.number().nullish(),
  netProfitMargin: z.number().nullish(),
  payoutRatio: z.number().nullish(),
  dividendYield: z.number().nullish(),
  netProfitGrowthQoq: z.number().nullish(),
  netProfitGrowthYoy: z.number().nullish(),
  netProfitGrowthPastFiveYears: z.number().nullish(),
  avgYoyRevenueGrowthLastThreeQuarters: z.number().nullish(),
  avgYoyEpsGrowthLastThreeQuarters: z.number().nullish(),
  avgYoyNetProfitGrowthLastThreeQuarters: z.number().nullish(),
});

export const fundamentalTWSchema = fundamentalSharedSchema;

export const fundamentalUSSchema = fundamentalSharedSchema.merge(
  z.object({
    forwardPe: z.number().nullish(),
    peg: z.number().nullish(),
    epsGrowthNextYear: z.number().nullish(),
    institutionalOwnership: z.number().nullish(),
  })
);

export type FundamentalSharedInput = z.infer<typeof fundamentalSharedSchema>;
export type FundamentalTWInput = z.infer<typeof fundamentalTWSchema>;
export type FundamentalUSInput = z.infer<typeof fundamentalUSSchema>;

export const fundamentalTWInitialState: FundamentalTWInput = {
  pe: undefined,
  ps: undefined,
  pb: undefined,
  epsGrowthQoq: undefined,
  epsGrowthYoy: undefined,
  epsGrowthPastFiveYears: undefined,
  revenueGrowthQoq: undefined,
  revenueGrowthYoy: undefined,
  revenueGrowthPastFiveYears: undefined,
  evOverEbitda: undefined,
  roe: undefined,
  roa: undefined,
  roic: undefined,
  currentRatio: undefined,
  quickRatio: undefined,
  debtEquityRatio: undefined,
  grossMargin: undefined,
  operatingMargin: undefined,
  netProfitMargin: undefined,
  payoutRatio: undefined,
  dividendYield: undefined,
  netProfitGrowthQoq: undefined,
  netProfitGrowthYoy: undefined,
  netProfitGrowthPastFiveYears: undefined,
  avgYoyRevenueGrowthLastThreeQuarters: undefined,
  avgYoyEpsGrowthLastThreeQuarters: undefined,
  avgYoyNetProfitGrowthLastThreeQuarters: undefined,
};

export const fundamentalUSInitialState: FundamentalUSInput = {
  ...fundamentalTWInitialState,
  forwardPe: undefined,
  peg: undefined,
  epsGrowthNextYear: undefined,
  institutionalOwnership: undefined,
};
