import { createSlice } from "@reduxjs/toolkit";

export interface SearchDialogState {
  isOpen: boolean;
}

const initialState: SearchDialogState = {
  isOpen: false,
};

export const searchDialogSlice = createSlice({
  name: "searchDialog",
  initialState,
  reducers: {
    openSearchDialog(state) {
      state.isOpen = true;
    },
    closeSearchDialog(state) {
      state.isOpen = false;
    },
  },
});

export const { openSearchDialog, closeSearchDialog } = searchDialogSlice.actions;

export default searchDialogSlice.reducer;
