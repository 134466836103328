import { useSession } from "next-auth/react";
import { useMemo } from "react";

export const useAuth = () => {
  const session = useSession();
  return useMemo(
    () => ({
      backendToken: session.data?.user.backendToken,
      profile: session.data?.user,
      isFetchingUserData: session.status === "loading",
      isAuthenticated: session.status === "authenticated",
      isUnauthenticated: session.status === "unauthenticated",
      isFreeUser: session.data?.user.plan === "Free",
      isBeginningUser: session.data?.user.plan.includes("Beginning"),
      isBasicUser: session.data?.user.plan.includes("Basic"),
      isPremiumUser: session.data?.user.plan.includes("Premium"),
    }),
    [session]
  );
};
