import { createSlice } from "@reduxjs/toolkit";

export type VIPDialogState = {
  isOpen: boolean;
  redirectUrl: string;
};

const initialState: VIPDialogState = {
  isOpen: false,
  redirectUrl: "",
};

export const VIPDialogSlice = createSlice({
  name: "VIPDialog",
  initialState,
  reducers: {
    openVIPDialog(state) {
      state.isOpen = true;
    },
    closeVIPDialog(state) {
      state.isOpen = false;
    },
    setStripeRedirectUrl(state, action) {
      state.redirectUrl = action.payload;
    },
  },
});

export const { openVIPDialog, closeVIPDialog, setStripeRedirectUrl } =
  VIPDialogSlice.actions;

export default VIPDialogSlice.reducer;
