"use client";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { User } from "next-auth/core/types";
import { SessionProvider } from "next-auth/react";
import dynamic from "next/dynamic";
import { usePathname } from "next/navigation";
import { Suspense, useState } from "react";
import { isDesktop } from "react-device-detect";
import { Provider } from "react-redux";

import { GoogleOneTap } from "@/components/google-one-tap";
import { NavigationEvents } from "@/components/navigation-events";
import { store } from "@/context/redux/store";
import { LAYOUT_VARIABLES } from "@/data/constants/layout";
import { useSyncSession } from "@/features/auth/login/services/sync-user";
import { Lang } from "@/i18n-config";

import { StyledProvider } from "ui/widget/styled-provider";

import { useIntercom } from "../../hooks/use-intercom";
import { Analytics } from "./Analytics";

const LoginDialog = dynamic(() =>
  import("@/components/blocked-dialog/login-dialog/index").then(
    (mod) => mod.LoginDialog
  )
);

const SubscribeDialog = dynamic(() =>
  import("@/components/blocked-dialog/subscribe-dialog").then(
    (mod) => mod.SubscribeDialog
  )
);

const VIPDialog = dynamic(() =>
  import("@/features/account/components/subscription-details/VIPDialog").then(
    (mod) => mod.VIPDialog
  )
);

const EventDialog = dynamic(() =>
  import("@/components/event-dialog/EventDialog").then((mod) => mod.EventDialog)
);

const RestrictionBackDrop = dynamic(() =>
  import("@/components/blocked-dialog/restriction-back-drop").then(
    (mod) => mod.RestrictionBackDrop
  )
);

const MultiDeviceConfirmationDialog = dynamic(() =>
  import(
    "@/components/blocked-dialog/multi-device-dialog/MultiDeviceConfirmationDialog"
  ).then((mod) => mod.MultiDeviceConfirmationDialog)
);

type Props = {
  lang: Lang;
  children: React.ReactNode;
  intercomAppId: string;
  intercomSecretKey: string;
  user?: User;
};

export const ClientLayout = ({
  lang,
  children,
  intercomAppId,
  intercomSecretKey,
  user,
}: Props) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
            staleTime: 30 * 60 * 1000,
            cacheTime: 60 * 60 * 1000,
          },
        },
      })
  );

  return (
    <SessionProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        <Provider store={store}>
          <StyledProvider
            rootId="__next"
            fontFamily={
              lang === "zh" ? "Noto Sans SC, sans-serif" : "Inter, sans-serif"
            }
          >
            <Container
              user={user}
              intercomAppId={intercomAppId}
              intercomSecretKey={intercomSecretKey}
            >
              <div style={LAYOUT_VARIABLES}>{children}</div>
              <Suspense>
                <NavigationEvents />
              </Suspense>
            </Container>
          </StyledProvider>
        </Provider>
      </QueryClientProvider>
    </SessionProvider>
  );
};

const Container = ({
  children,
  user,
  intercomAppId,
  intercomSecretKey,
}: {
  children: React.ReactNode;
  intercomAppId: string;
  intercomSecretKey: string;
  user?: User;
}) => {
  const pathname = usePathname();
  const isAuthPage = pathname?.includes("/auth/");

  useSyncSession();
  useIntercom({
    appId: intercomAppId,
    secret: intercomSecretKey,
    userInfo: user,
    featureToggle:
      !process.env.CI && process.env.NODE_ENV === "production" && isDesktop,
  });

  return (
    <>
      {children}
      {isAuthPage ? null : <GoogleOneTap />}
      <BlockedDialogs />
      <EventDialog />
      <Suspense>
        <Analytics />
      </Suspense>
    </>
  );
};

const BlockedDialogs = () => (
  <>
    <LoginDialog />
    <SubscribeDialog />
    <RestrictionBackDrop />
    <VIPDialog />
    <MultiDeviceConfirmationDialog />
  </>
);
