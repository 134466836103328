"use client";

import { usePathname } from "next/navigation";

import { links } from "@/data/links";
import { useLang } from "@/hooks/use-lang";
import { useOneTapSignIn } from "@/hooks/use-one-tap-sign-in";

export const GoogleOneTap = () => {
  const { lang } = useLang();
  const pathname = usePathname();

  useOneTapSignIn({
    callbackUrl: !pathname.includes("/my")
      ? `/${lang}${links.myAnalysisSearch}`
      : undefined,
    redirect: true,
    parentContainerId: "oneTap",
  });
  return <div id="oneTap" className="fixed right-0 top-0 z-[9999999999]" />;
};
