/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ScreenerStrategyState {
  type: "user" | "recommender" | "none";
  name: string;
  description: string;
  strategyId: string | null;
}

const initialState: ScreenerStrategyState = {
  type: "none",
  name: "",
  description: "",
  strategyId: null,
};

export const screenerStrategySlice = createSlice({
  name: "screenerStrategy",
  initialState,
  reducers: {
    selectScreenerStrategy: (
      state,
      action: PayloadAction<ScreenerStrategyState>
    ) => {
      state.type = action.payload.type;
      state.strategyId = action.payload.strategyId;
      state.name = action.payload.name;
      state.description = action.payload.description;
    },

    updateScreenerStrategyInfo: (
      state,
      action: PayloadAction<{
        name: string;
        description: string;
      }>
    ) => {
      state.name = action.payload.name;
      state.description = action.payload.description;
    },

    clearScreenerStrategy: (state) => {
      state.type = "none";
      state.strategyId = null;
      state.name = "";
      state.description = "";
    },
  },
});

export const {
  selectScreenerStrategy,
  clearScreenerStrategy,
  updateScreenerStrategyInfo,
} = screenerStrategySlice.actions;

export default screenerStrategySlice.reducer;
