import { useQuery } from "@tanstack/react-query";
import * as z from "zod";

import { NIKE_URL } from "@/data/constants/url";
import { useAuth } from "@/features/auth/shared/hooks/use-auth";
import { checkPlanIsUsageRestricted } from "@/features/stripe/utils/stripe-plan";
import { useLang } from "@/hooks/use-lang";

const responseSchema = z.object({
  data: z.object({
    available: z.number(),
    blocked: z.boolean(),
    remains: z.number(),
  }),
  message: z.string(),
});

export type ServiceQuotaData = z.infer<typeof responseSchema>["data"];

type Params = {
  token: string;
  lang: string;
};

export const getServiceQuota = async ({
  token,
  lang,
}: Params): Promise<ServiceQuotaData> => {
  const endpoint = "/service-quota";

  const res = await fetch(`${NIKE_URL}${endpoint}`, {
    method: "GET",
    headers: {
      "X-User-Profile": token,
      "Accept-Language": lang,
    },
  });

  if (!res.ok) {
    throw new Error(`API failed (${endpoint})`);
  }

  return responseSchema.parse(await res.json()).data;
};

export const useGetServiceQuota = () => {
  const { backendToken, profile } = useAuth();
  const currentPlan = profile?.plan ?? "Free";
  const enabled =
    !!backendToken && checkPlanIsUsageRestricted({ plan: currentPlan });
  const { lang } = useLang();

  return useQuery<ServiceQuotaData>(
    ["service-quota", lang, backendToken],
    () => getServiceQuota({ token: backendToken ?? "", lang }),
    {
      enabled,
    }
  );
};
