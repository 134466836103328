import { z } from "zod";

// Define Zod schemas
export const powerSqueezeSchema = z.array(z.number());
export type PowerSqueezeType = z.infer<typeof powerSqueezeSchema>;

export const surfingTrendSchema = z
  .union([z.literal(0), z.literal(1), z.literal(-1)])
  .transform(Number);
export const enum SurfingTrendType {
  NONE = 0,
  RISE = 1,
  FALL = -1,
}

export const meanRevertingRadarSchema = z
  .union([z.literal(0), z.literal(1), z.literal(-1)])
  .transform(Number);
export const enum MeanRevertingRadarType {
  NONE = 0,
  UP = 1,
  DOWN = -1,
}

export const fiveScoreSchema = z.object({
  value: z.array(z.number()).optional(),
  trend: z.array(z.number()).optional(),
  swing: z.array(z.number()).optional(),
  dividend: z.array(z.number()).optional(),
  chip: z.array(z.number()).optional(),
});

export const powerSqueezeConfigSchema = z.object({
  disabled: z.boolean(),
  value: z.array(z.number()),
});

export const surfingTrendConfigSchema = z.object({
  day: surfingTrendSchema,
  week: surfingTrendSchema,
  month: surfingTrendSchema,
});

export const meanRevertingRadarConfigSchema = z.object({
  day: meanRevertingRadarSchema,
  week: meanRevertingRadarSchema,
  month: meanRevertingRadarSchema,
});

export const growinInputSchema = z.object({
  fiveScore: fiveScoreSchema,
  powerSqueezeDay: powerSqueezeConfigSchema,
  powerSqueezeWeek: powerSqueezeConfigSchema,
  powerSqueezeMonth: powerSqueezeConfigSchema,
  surfingTrend: surfingTrendConfigSchema,
  meanRevertingRadar: meanRevertingRadarConfigSchema,
});

// Infer types from schemas
export type GrowinInput = z.infer<typeof growinInputSchema>;
export type FiveScoreKey = keyof z.infer<typeof fiveScoreSchema>;

export const GrowinInputInitialState: GrowinInput = {
  fiveScore: {
    value: [],
    trend: [],
    swing: [],
    dividend: [],
    chip: [],
  },
  powerSqueezeDay: {
    disabled: true,
    value: [],
  },
  powerSqueezeWeek: {
    disabled: true,
    value: [],
  },
  powerSqueezeMonth: {
    disabled: true,
    value: [],
  },
  surfingTrend: {
    day: SurfingTrendType.NONE,
    week: SurfingTrendType.NONE,
    month: SurfingTrendType.NONE,
  },
  meanRevertingRadar: {
    day: MeanRevertingRadarType.NONE,
    week: MeanRevertingRadarType.NONE,
    month: MeanRevertingRadarType.NONE,
  },
};
