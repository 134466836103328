import { usePathname } from "next/navigation";
import { useEffect } from "react";

/**
 * @param pathnames
 * @param callback
 * @returns void
 * @description
 * usePathnameChange is a hook that listens to pathname change.
 * It will call the callback function when the pathname matches the pathnames.
 */

export const usePathnameChange = ({
  pathnames,
  callback,
}: {
  pathnames: (string | RegExp)[];
  callback?: (pathname?: string) => void;
}) => {
  const pathname = usePathname();
  const matchPathname = pathnames.includes("/")
    ? true // if pathnames includes "/", it will always match
    : pathnames?.some((p) =>
        p instanceof RegExp ? p.test(pathname) : pathname.includes(p)
      );

  useEffect(() => {
    if (matchPathname) {
      callback?.(pathname);
    }
  }, [callback, matchPathname, pathname]);
};
