import { z } from "zod";

import { TV_URL } from "@/data/constants/url";
import { stripePlans } from "@/features/stripe/data/stripe-plan";
import { LoginError } from "@/services/api-error";

type LoginParams =
  | {
      authProvider: "Original" | "Business";
      identifier: string;
      password: string;
    }
  | {
      authProvider: "Google";
      email: string;
      name: string;
      picture: string;
      providerUserId: string;
    };

export type AuthProvider = LoginParams["authProvider"];

export const userDataSchema = z.object({
  token: z.string(),
  profile: z.object({
    id: z.string(),
    name: z.string(),
    email: z
      .string()
      .nullable()
      .transform((email) => email ?? ""),
    subscribed: z.boolean(),
    subscribing: z.boolean(),
    plan: z.enum(stripePlans),
    subscribedAt: z.string().nullable(),
    expireAt: z.string().nullable(),
    discount: z
      .array(z.string())
      .nullish()
      .transform((discount) => discount ?? []),
    signUpAt: z
      .string()
      .nullish()
      .transform((signUpAt) => signUpAt ?? "-"),
  }),
});

export type UserData = z.infer<typeof userDataSchema>;

export const login = async (params: LoginParams): Promise<UserData> => {
  const { authProvider, ...restParams } = params;
  const endpoint = "/user/sign-in";

  const res = await fetch(`${TV_URL}${endpoint}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      platform: "stock_mining",
      "X-User-Auth": authProvider,
      "Accept-Language": "zh",
    },
    body: JSON.stringify(restParams),
  });

  if (res.status === 403) throw new Error(LoginError.invalidCredentials);
  if (res.status === 401) throw new Error(LoginError.emailNotVerified);
  if (!res.ok) throw new Error(`API failed (${endpoint})`);

  return userDataSchema.parse(await res.json());
};
