"use client";

import { usePathname, useSearchParams } from "next/navigation";
import Script from "next/script";
import { useEffect } from "react";

import { useAuth } from "@/features/auth/shared/hooks/use-auth";

declare const window: Window & { dataLayer: Record<string, unknown>[] };

const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID ?? "";

/**
 * This component is responsible for GTM initialization and pageview tracking.
 * reference: https://dev.to/valse/how-to-setup-google-tag-manager-in-a-next-13-app-router-website-248p
 */
export const Analytics = () => {
  const { profile } = useAuth();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (pathname) {
      pageview({ url: pathname });
      if (profile) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ userId: profile.id });
      }
    }
  }, [pathname, searchParams, profile]);

  if (process.env.NODE_ENV !== "production") return null;

  return (
    <>
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        />
      </noscript>
      <Script
        id="gtm-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer', '${GTM_ID}');`,
        }}
      />
    </>
  );
};

const pageview = ({ url }: { url: string }) => {
  window.dataLayer?.push({
    event: "pageview",
    page: url,
  });
};
